import { ReactNode } from "react";
import styled, {
  ThemeProvider,
  ThemedStyledInterface,
} from "styled-components";
import { RenderContent } from "../../hooks/contentful/useHomePageHeroPromotion";
import { Color, Font, rem, responsive } from "../../utils/style";
import { Typography } from "../../utils/styleDesignSystem";
import RitualButton from "../global/RitualButton";

type Theme = {
  headline: { color: string };
  button: { color: string; background: string };
  buttonHover: { color: string; background: string };
};
const themeStyled = styled as ThemedStyledInterface<Theme>;

const themeTypes = ["default", "white"] as const;
type Themes = (typeof themeTypes)[number];

const isValidTheme = (theme: any): theme is Themes =>
  themeTypes.includes(theme);
export const validTheme = (theme: any): Themes =>
  isValidTheme(theme) ? theme : "default";

export const themes: {
  [key in Themes]: Theme;
} = {
  default: {
    headline: { color: Color.ritualBlue },
    button: { color: Color.white, background: Color.ritualBlue },
    buttonHover: { color: Color.ritualBlue, background: Color.white },
  },
  white: {
    headline: { color: Color.white },
    button: { color: Color.ritualBlue, background: Color.white },
    buttonHover: { color: Color.ritualBlue, background: Color.ritualYellow },
  },
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  ${responsive.sm`
    gap: 32px;
  `}
  &:has(.legacy-headline) {
    gap: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  text-align: center;
  max-width: 327px;

  ${responsive.sm`
    gap: 24px;
    max-width: 340px;
  `}

  ${responsive.md`
    max-width: 568px;
  `}
`;

export const Headline = themeStyled.h1`
  ${Typography.display}
  pointer-events: none;
  z-index: 1;
  color: ${({ theme }) => theme.headline.color};
  margin: 0;
  text-align: center !important;
  line-height: ${rem(50)};

  ${responsive.sm`
    line-height: ${rem(60)};
    letter-spacing: -1.758px;
    margin-bottom: -24px;
  `}

  ${responsive.md`
    line-height: ${rem(72)};
    margin-bottom: unset;
  `}

  em {
    ${Font.dutch};
    font-weight: 400;
    line-height: 60px;
    letter-spacing: -1.758px;
  }

  s {
    text-decoration-thickness: 0.15rem;

    ${responsive.md`
      text-decoration-thickness: 0.25rem;
    `}
  }
`;

const LegacyHeadline = themeStyled.h1`
  pointer-events: none;
  z-index: 1;

  ${Font.circular};
  color: ${({ theme }) => theme.headline.color};
  font-size: ${rem(40)};
  font-weight: 500;
  letter-spacing: -0.6px;
  line-height: ${rem(45)};
  text-align: center !important;
  width: 69%;
  max-width: 247px;
  margin-bottom: 16px;

  &.full-width {
    width: 100%;
    max-width: unset;
  }

  ${responsive.sm`
    font-size: ${rem(58)};
    line-height: ${rem(60)};
    letter-spacing: -1.76px;
    width: 348px;
    max-width: none;
  `};
  ${responsive.md`
    font-size: ${rem(58)};
    line-height: ${rem(60)};
    margin-bottom: 12px;
  `}
  ${responsive.lg`
    font-size: ${rem(76)};
    letter-spacing: -2.3px;
    line-height: ${rem(74)};
    margin-bottom: 20px;
    width: 456px;
  `};

  em {
    ${Font.dutch};
  }

  p {
    margin: 0;
  }

  &.three-line-offsets {
    text-align: left !important;

    div {
      text-align: right;
      ${responsive.lg`
        padding: 6px 0;
      `}
    }

    div:nth-of-type(2n) {
      padding-right: 12px;
      ${responsive.sm`
        padding-right: 18px;
      `}
    }
  }
`;

export const Subhead = styled.p`
  ${Typography.body1}
  margin: 0;
  max-width: 213px;
  ${responsive.sm`
    max-width: 100%;
  `};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;

  ${responsive.sm`
    flex-direction: row;
  `}
`;

const ShopButtonWrapper = styled.div`
  margin-right: 24px;
  margin-bottom: 12px;
  width: 100%;

  &:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }

  ${responsive.sm`
    margin-right: 16px;
    width: 170px;
  `}
`;

const ShopButton = themeStyled(RitualButton)`
  width: 100%;
  padding: 12px 24px;

  color: ${({ theme }) => theme.button.color};
  background-color: ${({ theme }) => theme.button.background};
  border-color: ${({ theme }) => theme.button.background};

  span {
    padding: 0;
  }

  &&:hover {
    color: ${({ theme }) => theme.buttonHover.color} !important;
    background-color: ${({ theme }) => theme.buttonHover.background} !important;
    border-color: ${({ theme }) => theme.buttonHover.background} !important;
  }

  && span,
  && span:hover {
    background-color: inherit !important;
  }
`;

type DangerousHeadlineProps = {
  title: string;
  className?: string;
};
export const DangerousHeadline = ({
  title,
  className,
}: DangerousHeadlineProps) => (
  <LegacyHeadline
    className={`legacy-headline ${className}`}
    dangerouslySetInnerHTML={{
      __html: title,
    }}
  />
);

type CtaProp = { text: string; to: string };
type HeroContentProps = {
  children: ReactNode;
  ctas?: CtaProp[];
  onCtaClick?: (cta: CtaProp) => void;
  theme?: Themes;
};
const HeroContent = ({
  children,
  ctas = [],
  onCtaClick,
  theme = "default",
}: HeroContentProps) => (
  <ThemeProvider theme={themes[theme]}>
    <Wrapper>
      <ContentWrapper>{children}</ContentWrapper>

      <ButtonsWrapper>
        {ctas && ctas.map((cta) => (
          <ShopButtonWrapper key={cta.text}>
            <ShopButton
              to={cta.to}
              onClick={() => onCtaClick?.(cta)}
              title={cta.text}
              className="xs-short"
            >
              {cta.text}
            </ShopButton>
          </ShopButtonWrapper>
        ))}
      </ButtonsWrapper>
    </Wrapper>
  </ThemeProvider>
);

export const renderHomePageHeroContent: RenderContent = {
  H1: Headline,
  P: Subhead,
};

export default HeroContent;
