import styled from "styled-components";

// Redux
import { getProductForSku } from "../../utils/planToProduct";

// Components
import Container from "../Container";
import MagicLink from "../MagicLink";
import Row from "../Row";
import ScrollableOverflow from "../ScrollableOverflow";
import Text from "../Text";
import BundleCard from "../shop/BundleCard";
import ProductCard from "../shop/ProductCard";

// Utils
import { Color, Opacity, rem, responsive } from "../../utils/style";

const FeaturedMerchandiseSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 0 20px;

  ${responsive.sm`
    padding: 0;
  `}
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 24px;

  ${responsive.md`
    margin-bottom: 32px;
  `}

  h2 {
    font-size: ${rem(24)};
    line-height: ${rem(34)};
    letter-spacing: -0.28px;
    margin-bottom: 0;

    ${responsive.md`
      font-size: ${rem(40)};
      line-height: ${rem(54)};
      letter-spacing: -1.17px;
    `}
  }
`;

const ShopLink = styled(MagicLink)`
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  border-bottom: 2px solid ${Color.ritualBlue};
  text-decoration: none;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `}

  &:hover {
    opacity: ${Opacity.light};
  }
`;

const CardWrapper = styled.div`
  width: calc(100vw * 0.4);
  margin-right: 16px;

  &:last-of-type {
    margin-right: 0;
  }

  ${responsive.sm`
    width: unset;
    flex: 1 1 25%;
    margin-right: 20px;
  `}

  ${responsive.md`
    margin-right: 26px;
  `}

  ${responsive.lg`
    margin-right: 30px;
  `}
`;

const CardContainer = styled.div`
  height: 100%;

  ${responsive.sm`
    height: unset;
  `}
`;

const FeaturedMerchandise = (props) => {
  const {
    merchandisingOption = "Products",
    products,
    bundles,
    className,
  } = props;

  const contentfulProductSkus = products.map((product) => product.sku);

  const featuredProducts = contentfulProductSkus.map((sku) => {
    return getProductForSku(sku);
  });

  function renderMerchandisingText(merchandisingOption) {
    switch (merchandisingOption) {
      case "Products":
        return renderProductsText();
      case "Bundles":
        return renderBundlesText();
      default:
        return renderProductsText();
    }
  }

  function renderProductsText() {
    return (
      <TextContainer>
        <h2>
          <Text
            id="home.featured-products.heading"
            defaultMessage="Featured Products"
          />
        </h2>
        <ShopLink to="/shop/all">
          <Text id="home.featured-products.link" defaultMessage="Shop All" />
        </ShopLink>
      </TextContainer>
    );
  }

  function renderBundlesText() {
    return (
      <TextContainer>
        <h2>
          <Text
            id="home.featured-bundles.heading"
            defaultMessage="Featured Bundles"
          />
        </h2>
        <ShopLink to="/shop/all">
          <Text id="home.featured-bundles.link" defaultMessage="Shop All" />
        </ShopLink>
      </TextContainer>
    );
  }

  function renderFeaturedMerchandiseCards(merchandisingOption) {
    switch (merchandisingOption) {
      case "Products":
        return renderFeaturedProducts();
      case "Bundles":
        return renderFeaturedBundles();
      default:
        return renderFeaturedProducts();
    }
  }

  function renderFeaturedProducts() {
    return featuredProducts.map((product) => {
      return (
        <CardWrapper key={product.name}>
          <ProductCard
            product={product}
            hideDescription={false}
            hideCta={true}
            location="Homepage Featured Products"
            showQuickView={false}
          />
        </CardWrapper>
      );
    });
  }

  function renderFeaturedBundles() {
    return bundles.map((productBundle, i) => (
      <CardWrapper key={`${productBundle.title}-${i}`}>
        <BundleCard
          productBundle={productBundle}
          hideCta={true}
          location="Homepage Featured Bundles"
          showTextPrice={true}
        />
      </CardWrapper>
    ));
  }

  return (
    <Container className={className}>
      <Row>
        <FeaturedMerchandiseSection>
          {renderMerchandisingText(merchandisingOption)}

          <ScrollableOverflow
            centered={false}
            bleedLeft={true}
            bleedRight={true}
            lockOnDrag={true}
            className="d-block d-sm-none"
          >
            {renderFeaturedMerchandiseCards(merchandisingOption)}
          </ScrollableOverflow>
          <CardContainer className="d-none d-sm-flex">
            {renderFeaturedMerchandiseCards(merchandisingOption)}
          </CardContainer>
        </FeaturedMerchandiseSection>
      </Row>
    </Container>
  );
};

export default FeaturedMerchandise;
